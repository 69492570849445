// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import FreeTrial from "../../blocks/FreeTrial/src/FreeTrial";
import Library2 from "../../blocks/Library2/src/Library2";
import Share from "../../blocks/share/src/Share";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Sorting from "../../blocks/sorting/src/Sorting";
import PasswordProtectedPages from "../../blocks/PasswordProtectedPages/src/PasswordProtectedPages";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import DownloadPermissions from "../../blocks/DownloadPermissions/src/DownloadPermissions";
import Notes from "../../blocks/Notes/src/Notes";
import ConversationThreading from "../../blocks/ConversationThreading/src/ConversationThreading";
import PasswordEncryption from "../../blocks/PasswordEncryption/src/PasswordEncryption";
import Comments from "../../blocks/Comments/src/Comments";
import Analytics from "../../blocks/analytics/src/Analytics";
import Referrals from "../../blocks/Referrals/src/Referrals";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import InappPurchasing from "../../blocks/InappPurchasing/src/InappPurchasing";
import Payments from "../../blocks/Payments/src/Payments";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import Hashtags from "../../blocks/Hashtags/src/Hashtags";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Search from "../../blocks/search/src/Search";
import Gallery from "../../blocks/Gallery/src/Gallery";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import FeatureSettings2 from "../../blocks/FeatureSettings2/src/FeatureSettings2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import SearchHistory from "../../blocks/SearchHistory/src/SearchHistory";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import KeywordSearch from "../../blocks/KeywordSearch/src/KeywordSearch";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import LiveChatSummary from "../../blocks/LiveChatSummary/src/LiveChatSummary";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import FileCompression from "../../blocks/FileCompression/src/FileCompression";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Themes from "../../blocks/Themes/src/Themes";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Videos from "../../blocks/videos/src/Videos";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import LogoDesign2 from "../../blocks/LogoDesign2/src/LogoDesign2";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import Groups from "../../blocks/Groups/src/Groups";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";



const routeMap = {
FreeTrial:{
 component:FreeTrial,
path:"/FreeTrial"},
Library2:{
 component:Library2,
path:"/Library2"},
Share:{
 component:Share,
path:"/Share"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
PasswordProtectedPages:{
 component:PasswordProtectedPages,
path:"/PasswordProtectedPages"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
DownloadPermissions:{
 component:DownloadPermissions,
path:"/DownloadPermissions"},
Notes:{
 component:Notes,
path:"/Notes"},
ConversationThreading:{
 component:ConversationThreading,
path:"/ConversationThreading"},
PasswordEncryption:{
 component:PasswordEncryption,
path:"/PasswordEncryption"},
Comments:{
 component:Comments,
path:"/Comments"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
InappPurchasing:{
 component:InappPurchasing,
path:"/InappPurchasing"},
Payments:{
 component:Payments,
path:"/Payments"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Search:{
 component:Search,
path:"/Search"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
FeatureSettings2:{
 component:FeatureSettings2,
path:"/FeatureSettings2"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
SearchHistory:{
 component:SearchHistory,
path:"/SearchHistory"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
KeywordSearch:{
 component:KeywordSearch,
path:"/KeywordSearch"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
LiveChatSummary:{
 component:LiveChatSummary,
path:"/LiveChatSummary"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
FileCompression:{
 component:FileCompression,
path:"/FileCompression"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Themes:{
 component:Themes,
path:"/Themes"},
UserProfileAdvance:{
 component:UserProfileAdvance,
path:"/UserProfileAdvance"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Videos:{
 component:Videos,
path:"/Videos"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
LogoDesign2:{
 component:LogoDesign2,
path:"/LogoDesign2"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
Groups:{
 component:Groups,
path:"/Groups"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},

  Home: {
component:OTPInputAuth,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
